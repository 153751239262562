import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import styles from "./solution.module.scss"
import Img from "gatsby-image"

const Success = ({ data }) => {
  const {
    airtable: { data: solution },
  } = data
  return (
    <Layout
      pageTitle={solution.Success_Headline}
      solutionName={solution.Name}
      noSubFooter
      dark
    >
      <SEO title={`${solution.Success_Headline}`} />
      <Container fluid className={`pt-4 ${styles.solutionDetailContainer}`}>
        <Row className={`${styles.solutionDetailRow}`}>
          <Col
            className={`${styles.solutionDetailCol} d-flex flex-column align-items-center`}
          >
            <Img
              fluid={solution.Code.localFiles[0].childImageSharp.fluid}
              className={`${styles.successCode}`}
            />
            <p className={`mt-4 ${styles.codeDescription}`}>
              {solution.Code_Description}
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($name: String!) {
    airtable(data: { Name: { eq: $name } }) {
      data {
        Name
        Success_Headline
        Code_Description
        Code {
          localFiles {
            childImageSharp {
              fluid(quality: 90, maxWidth: 512) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

export default Success
